.details-page-form {
  width: 100%;
  background-color: var(--background-color);
  /* border: var(--thin-border);
    border-radius: var(--details-page-items-border-radius); */
  border-radius: var(--details-page-items-border-radius); 
  align-self: self-start;
}

.details-page .details-page-form {
  padding: 15px;
}

.details-form-actions {
  /* position: sticky;
    bottom: 0; */
  grid-column: span 2; /* Span the entire width of the grid */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: var(--border-radius);
  padding: 20px 0;
  background-color: var(--background-hover);
}

.fields-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 0;
}

.full-width-field {
  grid-column: span 2;
}

.fields-left,
.fields-right {
  width: 100%
}

.fields-left {
  justify-self: end; /* Align fields on the left to the end of their column */
}

.fields-right {
  justify-self: start; /* Align fields on the right to the start of their column */
}

.system-information {
  width: 40%;
  padding: 15px;
  background-color: var(--background-color);
  border: var(--thin-border);
  border-radius: var(--details-page-items-border-radius);
  align-self: self-start;
}

@media (max-width: 1024px) {
  .details-page {
    flex-direction: column;
  }

  .details-page-form {
    width: 100%;
  }

  .fields-layout {
    display: flex;
    flex-direction: column;
  }

  .system-information {
    width: 100%;
  }
}
