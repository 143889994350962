:root {
  /* ===== Colors ===== */
  /* --primary-color: #1c74d4; */
  --primary-color: #244c64;
  --primary-color-hover: #858c93;
  --primary-text-color: #383838;
  --text-color: #747474;
  --light-grey: #f0f0f0;
  --green-color: #15e415;
  --yellow-color: #ffff80;
  --red-color: #fc052b;
  --light-blue: #3498db;
  --blue-color: #299fdc;
  --orange-color: #ffa62f;
  --light-grey-color: #f9f9f9;
  --delete-button-color: #b80000;
  --secondary-text-color: #fff;
  --background-color: #fff;
  --background-hover: #f3f3f3;
  --link-color: #3081d0;

  /* --secondary-text-color: #545c6b; */
  --primary-background-color: #fff;
  --secondary-background-color: #f3f4f7;

  /* ===== btn ===== */
  --btn-primary-color: var(--primary-color);
  --btn-secondary-color: #cfedf9;

  /* ===== Font Weights ===== */
  --font-weight: 600;

  /* ====== Box Shadows ===== */
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* ====== padding =====*/
  /* --toolbar-padding: 12px; */
  --details-page-padding: 10px;

  /* ====== width =====*/
  --sidebar-width: 350px;
  --collapsed-sidebar-width: 58px;
  /* --details-page-width: 70%; */

  /* ====== height =====*/
  --toolbar-height: 50px;
  --details-page-modal-header: 80px;
  --logo-container-height: 45px;
  --breadcrumbs-height: 50px;

  /* ====== border =====*/
  --thin-border: 1px solid #d4d4d2;

  /* ====== border radius =====*/
  --border-radius: 4px;
  --details-page-items-border-radius: 8px;
  --modal-border-radius: 10px;

  /* ====== transition =====*/
  --width-transition: width 0.2s ease-in-out;
}

/* === Custom Scroll Bar CSS === */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  /* background: var(--primary-color); */
  background: grey;
  border-radius: 12px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: #8eaccd;
}
