.email-container {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  max-width: 100%;
  overflow: hidden;
}

.email-subject {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--primary-text-color);
}

.email-date {
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.email-body {
  font-size: 1rem;
  max-width: 100%;
  overflow: hidden;
}

.email-iframe {
  width: 100%;
  border: none;
}

.email-text {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--primary-text-color);
}