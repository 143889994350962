.parameters-container {
  margin-top: 20px;
}

.accordion-details .form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  grid-column: span 2; /* Span the entire width of the grid */
}

.enable-imap-toggle {
  grid-column: span 2;
}

@media (max-width: 1024px) {
  .accordion-details .form {
    display: flex;
    flex-direction: column;
  }
}
