.range-calculator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.caption {
    text-align: center;
}

.total-amount {
    font-size: 2.8vw;
    transition: font-size 0.2s;
}

.submit-button,
.date-range {
  display: flex;
  justify-content: center;
}

.filter-container {
    margin: 0 10px;
}

.date-fields-container {
    display: flex;
    gap: 10px;
}