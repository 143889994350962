.accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 6px;
}

.rotated {
  transform: rotate(90deg); /* Rotate the arrow when expanded */
  transition: transform 0.3s ease-in-out; /* Smooth transition for arrow rotation */
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 7px;
  color: var(--primary-text-color);
}

.accordion-header .text {
  margin-left: 10px;
}

.accordion-arrow {
  text-align: right;
}

.accordion-content {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 0; /* Collapsed by default */
  overflow: hidden; /* Hide overflowing content when collapsed */
  transition: max-height 0.3s ease-in-out; /* Smooth transition for expanding/collapsing */
}

.accordion-content.expanded {
  max-height: 250px; /* Adjust based on expected content height */
}

.accordion-content .link {
  text-decoration: none;
  color: inherit;
}

.accordion-content .link:hover,
.accordion-content li .active-content {
  color: var(--blue-color);
  background: transparent;
}

.accordion-content .text {
  margin-left: 20px;
}

/* List item styling with space for line and dot */
.accordion-content li {
  position: relative;
  padding-left: 20px; /* Space for dot and line */
}

/* Line pseudo-element */
.accordion-content li::before {
  content: '';
  position: absolute;
  left: 9px; /* Position the line at the center of the dot */
  top: 0;
  bottom: 0;
  width: 2px; /* Line thickness */
  background-color: gray; /* Line color */
  margin-bottom: 4px;
  margin-left: 10px;
}

/* Dot pseudo-element */
.accordion-content li::after {
  content: '';
  position: absolute;
  left: 6px; /* Position the dot on the line */
  top: 50%; /* Vertically center the dot */
  transform: translateY(-50%); /* Correct for vertical alignment */
  width: 8px; /* Diameter of the dot */
  height: 8px; /* Diameter of the dot */
  background-color: gray; /* Color of the dot */
  border-radius: 50%; /* Make it circular */
  margin-left: 10px;
}

.active {
  /* background-color: var(--primary-color); */
  background-color: var(--blue-color);
  color: var(--background-color);
  font-weight: 600;
  border-radius: var(--border-radius);
}