.sidebar {
    background-color: var(--primary-background-color);
    width: var(--sidebar-width);
    border-right: var(--thin-border);
    height: 100vh;
    position: relative;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--toolbar-height);
    border-bottom: var(--thin-border);
}

.logo-container img {
    transition: width 0.3s ease-in-out; /* Animate logo size */
}

.logo-container img.collapsed {
    width: 50px;
}

.sidebar .menu-items {
    overflow-y: auto; /* Hide scrollbar by default */
    overflow-x: hidden;
    height: calc(100vh - 2.3 * var(--toolbar-height));
    scrollbar-width: thin;
}

.sidebar .logout {
    display: flex;
    align-items: center;
    justify-content: end;
    border-top: var(--thin-border);
    background-color: var(--primary-background-color);
    position: absolute;
    bottom: 0;
    padding: 0.4rem 0.8rem;
    width: 100%;
}

.sidebar .logout .logout-text {
    font-weight: 600;
    font-size: 1.4rem;
}