.profile-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.buttons-container {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 0;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}

.horizontal-line {
  margin: 15px 0;
}

@media (max-width: 1024px) {
  .profile-container,
  .profile-form {
    display: flex;
    flex-direction: column;
  }
}
