.email-to-lead-field-mapping {
  margin-top: 30px;
}

.email-to-lead-field-mapping hr {
  margin: 20px 0;
}

.field-map-loading-skeleton {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.email-to-lead-field-mapping .field-map-heading {
  font-size: 24px;
  margin: 10px 0;
}

.field-mapping-form,
.parsing-rules {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.field-mapping-form table,
.parsing-rules table {
  width: 100%;
  border-collapse: collapse;
}

.parsing-rules table th,
.parsing-rules table th {
  text-align: left;
}

.parsing-rules th,
.parsing-rules td {
  padding: 10px; /* Add padding for space inside each cell */
  text-align: left;
}

.parsing-rules td {
  padding: 10px 15px; /* More padding on the left and right */
}

.parsing-rules th {
  padding: 10px 15px;
}

.field-mapping-form th {
  padding: 12px;
  text-align: left;
}

.field-mapping-form tfoot td{
  text-align: left;
  padding-top: 10px;
  border-top: var(--thin-border);
}
