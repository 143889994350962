.page-layout {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.page-layout .layout-container {
  width: 100%;
  overflow: hidden;
}

.layout-container main {
  margin: 10px;
  padding: 18px;
  height: calc(100vh - var(--toolbar-height) - 10px);
  overflow-y: auto;
}

.layout-container .entity-layout {
  /* background: var(--background-color); */
  /* border: var(--thin-border); */
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

.layout-container .emails_page {
  overflow-y: hidden;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.access-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  color: red;
  font-weight: bold;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: red;
  font-weight: bold;
  font-size: 18px;
}
