.Custom-field-container {
  margin-bottom: 20px;
  position: relative;
}

/* .field-label {
  position: relative;
} */

.Custom-field-container .required {
  color: red;
  margin-left: 4px;
}

.Custom-field-container input[type="text"],
.Custom-field-container input[type="password"],
.Custom-field-container input[type="search"],
.Custom-field-container input[type="number"],
.Custom-field-container input[type="date"],
.Custom-field-container input[type="month"],
.Custom-field-container input[type="time"],
.Custom-field-container input[type="email"],
.Custom-field-container input[type="output"],
.Custom-field-container input[type="datetime-local"],
.Custom-field-container select,
.Custom-field-container textarea,
.lookup-search-filter {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
}

.Custom-field-container input[type="search"] {
  outline: none;
}

.Custom-field-container output {
  display: block;
}

.Custom-field-container .error {
  border: 2px solid red; /* Red border for error */
  background-color: #ffeeee; /* Light red background for error */
  color: red; /* Red text for error */
}

/* Style the error message */
.Custom-field-container .error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

/* Style for the info icon */
.Custom-field-container .info-icon {
  margin-left: 5px;
  cursor: pointer;
}

/* Style for the help text */
.Custom-field-container .help-text {
  position: absolute;
  background-color: #042c64;
  color: #fff;
  z-index: 100;
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  display: none;
}

.Custom-field-container .help-text::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #042c64 transparent transparent transparent;
}

/* Show the help text on hover */
.Custom-field-container .info-icon:hover + .help-text {
  display: inline-block;
}

/* Container for each radio button option */
.radio-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Style for disabled radio buttons */
.radio-option input[type="radio"]:disabled + label {
  color: #ccc;
  cursor: not-allowed; /* Change cursor to not-allowed for disabled options */
}

/*
  styles for the lookup field
*/

/* .lookup-search-filter {
  margin: 5px;
  padding: 5px;
} */

.lookup-field-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 2px 0 0 0;
  /* border: 1px solid #ccc; */
  width: 100%;
}

.popover-container {
  padding: 10px;
}

.lookup-field-option {
  padding: 8px 12px;
  cursor: pointer;
}

.lookup-field-option:hover {
  background-color: #f0f0f0;
  border-radius: var(--border-radius);
}

.lookup-field-input {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
}

.input-icon-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%; /* Adjust the top position to center the icon vertically */
  transform: translateY(-50%); /* Center the icon vertically */
  right: 5px;
  z-index: 2;
}

/* Field.module.css */
.input-wrapper {
  position: relative;
}

.input-field {
  width: 100%;
  padding-right: 30px; /* Adjust padding to make room for the loader */
}

.loader {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}