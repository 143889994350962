.icon-container,
.active-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
}

.icon-container {
    background-color: var(--light-grey);
    border-radius: var(--border-radius);
    /*  below css is for making the icons border lighter (mui icons are svg).
        stroke must have the same color as the background.
    */
    stroke: var(--background-color);
    stroke-width: 1;
}

.active-icon {
    font-weight: 600;
    background-color: var(--primary-color);
    color: var(--background-color);
    border-radius: var(--border-radius);
    /*  below css is for making the icons border lighter (mui icons are svg).
        stroke must have the same color as the background.
    */
    stroke: var(--primary-color);
    stroke-width: 1;
}