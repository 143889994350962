.formContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.header {
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .formContainer {
    grid-template-columns: 1fr;
  }
}
