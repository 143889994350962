.success_message,
.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon {
    color: green;
}