.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  border-bottom: var(--thin-border);
  width: 100%;
  height: var(--toolbar-height);
  overflow: hidden;
}

.toolbar .quick-links {
  display: flex;
  align-items: center;
  margin-left: 1.4rem;
  height: var(--toolbar-height);
}

.toolbar .quick-nav,
.toolbar .toolbar-icons {
  display: flex;
  gap: 1.4rem;
  margin-left: 1.4rem;
  list-style: none;
}

.toolbar .toolbar-icons {
  display: flex;
  align-items: center;
  gap: 1.4rem;
  margin-right: 2.2rem;
}

.toolbar .logged-in-user {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.4rem;
  border: var(--thin-border);
  border-radius: var(--border-radius);
}

.logged-in-user .logged-in-user-name {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar:hover {
  border: 2px solid var(--primary-color);
}

.user-menu-icon {
  padding: 1px;
  border: 1px solid transparent;
  cursor: pointer;
}

.popover-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 220px;
}

.current-user {
  color: #fff;
  background-color: var(--primary-color);
  text-align: center;
  padding: 5px 8px;
  border-radius: var(--border-radius);
}

.menu-item {
  margin: 0.5rem 0;
  text-decoration: none;
  color: var(--text-color);
}

.menu-item-button {
  border: none;
  background: none;
  padding: 0.5rem 0;
  text-align: left;
  color: var(--text-color);
  cursor: pointer;
  font: inherit;
}

.menu-item-button:hover,
.menu-item:hover {
  color: var(--accent-color);
}
