.kanban-container {
  overflow-x: hidden;
}

.kanban-board {
  display: flex;
  gap: 16px;
  margin-top: 5px;
  overflow-x: auto;
}

.navigate-to-list-view {
  text-decoration: none;
  color: var(--primary-color);
}

.kanban-column {
  flex: 1;
  background-color: #f7f7f7;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  min-width: 280px;
}

.kanban-column h2 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  min-height: 60px
}

.kanban-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  cursor: grab;
  user-select: none;
}