.stages {
    display: flex;
    border-radius: 5px;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
}

.stages li {
    flex: 1;
    text-align: center;
    float: left;
    font-size: 12px;
    line-height: 36px;
    color: var(--text-color);
    font-weight: 600;
    padding: 0 10px 0 30px;
    background: #c3c6d4;
    position: relative;
    /* cursor: pointer; */
    transition: background-color ease-in-out 0.2s;
    height: 36px;
}

.stages li:first-child {
    border-radius: 5px 0 0 5px;
}

.stages li:first-child:before {
    left: 14px;
}

.stages li:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 20px;
}

.stages li.active {
    color: var(--secondary-text-color);
    background: var(--green-color);
}

.stages li.closed-unsuccessful {
    color: var(--secondary-text-color);
    background: #D24545;
}

.stages li.closed-unsuccessful:after {
    background: #D24545;
}

.stages li.active:after {
    background: var(--green-color);
}

/* .stages li.active, .stages li:hover {
    color: var(--secondary-text-color);
    background: var(--green-color);
}

.stages li.active:after, .stages li:hover:after {
    background: var(--green-color);
} */

.stages li:after {
    content: "";
    position: absolute;
    top: 0;
    right: -18px;
    width: 36px;
    height: 36px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    background: #c3c6d4;
    box-shadow: 2px -2px 0 2px var(--background-color), 3px -3px 0 2px var(--background-color);
    border-radius: 0 5px 0 50px;
    transition: background-color ease-in-out 0.2s;
}

.stages li:last-child:after {
    content: none;
}