@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Poppins:ital,wght@0,100;0,200;0,300;1,200&family=Ubuntu:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', Arial, sans-serif;
  color: #383838;
  background: #f5f5f5;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

.list-view {
  margin-top: 15px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.full-width-chart {
  margin-top: 20px;
}

.dashboard-charts,
.dashboard-charts-three-columns {
  display: grid;
  gap: 15px;
  margin-top: 20px;
}

.dashboard-charts {
  grid-template-columns: repeat(2, 1fr);
}

.dashboard-charts-three-columns {
  grid-template-columns: repeat(3, 1fr);
}

.dashboard-charts > *,
.dashboard-charts-three-columns > * {
  min-height: 350px;
}

.record-form {
  display: flex;
  gap: 20px;
  width: 100%;
}

.record-form > form:first-child {
  flex: 0 0 70%;
}

.related-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-self: flex-start;
  width: 100%;
}

.related-list > * {
  border-radius: var(--details-page-items-border-radius);
  background-color: var(--background-color);
}

.truncate-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncate-ellipsis-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .dashboard-charts,
  .dashboard-charts-three-columns {
    grid-template-columns: repeat(1, 1fr);
  }

  .record-form {
    flex-direction: column;
  }

  .record-form > div:first-child {
    flex: 1;
  }
}
