.sidebar-items {
  /* padding: 10px; */
  font-size: 14px;
  list-style: none;
}

.sidebar-items .sidebar-list-items {
  margin: 0.6rem 0.4rem;
}

.sidebar-items a {
  display: flex;
  align-items: center;
  padding: 7px;
  color: var(--primary-text-color);
  font-weight: 300;
  text-decoration: none;
}

.sidebar-items .skeleton-item-menu-open {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 7px;
}

.skeleton-item-menu-closed {
  display: block;
  width: var(--sidebar-width);
  padding: 7px;
}

.sidebar-items .active,
.sidebar-items .active:hover {
  /* background-color: var(--primary-color); */
  background-color: var(--blue-color);
  color: var(--background-color);
  font-weight: 600;
  border-radius: var(--border-radius);
}

.sidebar-items .active span {
  /*  below css is for making the icons border lighter (mui icons are svg).
          stroke must have the same color as the background.
      */
  stroke: var(--primary-color);
  stroke-width: 1;
}

.sidebar-items a:hover,
.accordion:hover {
  background-color: var(--background-hover);
  border-radius: var(--border-radius);
}

.sidebar-items li .text {
  margin-left: 10px;
}
