.roles-and-permissions-page {
  margin-top: 20px;
}

.field-permissions-container {
  padding: 1rem;
}

.field-permissions-title {
  margin-bottom: 1rem;
}

.field-permissions-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--light-grey-color);
  border: var(--thin-border);
  border-radius: 4px;
  padding: 1rem;
}

.field-permissions-table th, .field-permissions-table td {
  border: 1px solid #e0e0e0;
  padding: 0.5rem;
  text-align: center;
}

.field-permissions-table th {
  background-color: #f0f0f0;
}

.field-permissions-table td:first-child {
  text-align: left;
}