.details-page {
  display: flex;
  padding: var(--details-page-padding);
  gap: 15px;
}

.stages-container {
  padding: var(--details-page-padding);
}

@media (max-width: 1024px) {
  .details-page {
    flex-direction: column;
  }
}
