.accordion-container {
    width: 100%;
}

.accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.rotated {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

.accordion-arrow {
    text-align: right;
}

/* Base styles for the list */
.accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.rotated {
    transform: rotate(90deg);
    /* Rotate the arrow when expanded */
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for arrow rotation */
}

.accordion-header {
    display: flex;
    align-items: center;
    padding: 7px;
    color: var(--primary-text-color);
}

.accordion-header .text {
    margin-left: 10px;
}

.accordion-arrow {
    text-align: right;
}

.accordion-content {
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: auto;
    transition: max-height 0.3s ease-in-out;
}

.accordion-content.expanded {
    max-height: 500px;
}