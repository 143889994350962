.multi-select-container {
  position: relative;
  width: 100%;
}

.options-header {
  text-align: center;
  color: #fff;
  background-color: var(--primary-color);
  padding: 5px 0;
}

.options-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #fff;
  list-style: none;
  font-size: 0.9rem;
  max-height: 250px;
  overflow-y: hidden;
  padding: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 3000;
}

.options-list {
  overflow-y: auto;
  max-height: 210px;
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f0f0f0;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.selected-option {
  display: flex;
  align-items: center;
  background-color: #37afe1;
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
}

.remove-option {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: color 0.2s;
}

.remove-option:hover {
  color: #ddd;
}
