.logo {
  width: 150px;
  /* margin-bottom: 20px; */
}

.company-website-link {
  color: var(--secondary-text-color);
  background-color: #40c4ff;
  text-decoration: none;
  padding: 10px;
  width: fit-content;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.company-website-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #40c4ff;
  z-index: -2;
}

.company-website-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--background-color);
  transition: all 0.3s;
  z-index: -1;
}

.company-website-link:hover {
  color: var(--primary-color);
}

.company-website-link:hover:before {
  width: 100%;
}

.laptop-frame {
  width: 45vw;
  height: auto;
  position: relative;
  background: #f8f8f8;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.laptop-frame::before {
  content: '';
  display: block;
  width: 60%;
  height: 10px;
  background: #333;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px 5px 0 0;
}

.dashboard-image {
  width: 100%;
  border-radius: 10px;
}

.forgot-password {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #3f51b5;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.error-message {
  height: 20px;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  width: 100%
}