.details-page-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--light-grey);
  border: 1px solid #ccc;
  border-radius: var(--modal-border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 2%;
  padding-bottom: 15px;
  height: 100vh;
  width: 80%;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 2000; /* Ensure modal is on top of other content */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1999; /* Ensure overlay is behind the modal */
}

.details-page-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--details-page-modal-header);
  padding: 0 10px;
  background-color: var(--background-color);
  border-top-right-radius: var(--modal-border-radius);
  border-top-left-radius: var(--modal-border-radius);
  border-bottom: var(--thin-border);
}

.title-and-tabs-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.details-page-modal-header .title-and-tabs-container p {
  font-weight: var(--font-weight);
  font-size: 20px;
}

.details-page-tabs {
  display: flex;
  gap: 10px;
  font-size: 14px;
  list-style: none;
}

.details-page-tabs li {
  cursor: pointer;
}

.quick-actions-and-close-icon-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.details-page-modal .modal-content {
  height: calc(100vh - var(--details-page-modal-header));
  padding-bottom: 3%;
  overflow-y: auto;
}