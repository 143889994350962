.text-component {
  font-size: 32px;
  font-weight: 600;
}

.red {
  color: var(--red-color);
}

.orange {
  color: var(--orange-color);
}

.green {
  color: var(--green-color);
}

.congratulations {
  font-size: 1.5em;
  color: var(--green-color);
}

.bottom-text {
  margin-top: 10px;
  white-space: nowrap;
  font-size: 1.5vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: font-size 0.2s;
}