.no-attachments {
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--background-color);
}

.attachments-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.attachment-card {
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-color);
}

.attachment-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
}

.attachment-details {
    margin-bottom: 12px;
}

.download-button {
    color: var(--light-blue);
}