.tab-header .header-text {
    font-weight: var(--font-weight);
    font-size: 20px;
}

.tab-header .info-text {
    font-size: 14px;
}

.selected-listview {
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: 8px;
    cursor: pointer;
    width: fit-content;
}