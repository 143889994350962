.accordion-container {
  margin-bottom: 10px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 6px;
  cursor: pointer;
  border-radius: var(--border-radius);
  color: var(--primary-text-color);
  background-color: #f5f5f5;
}

.header-text {
  flex: 1;
}

.accordion-content {
  padding: 10px;
}