.map-fields-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--blue-color);
  margin-bottom: 10px;
  vertical-align: center;
}

.form_container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form_container > div {
  margin-bottom: 15px;
}

.form_container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form_container select {
  width: 100%;
  padding: 8px;
  border: var(--thin-border);
  border-radius: 4px;
}

.error {
  border: 2px solid red;
  background-color: #ffeeee;
  color: red;
}

.error-message {
  text-align: center;
  color: red;
  margin-top: 4px;
}