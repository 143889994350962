.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-container .caption {
  margin-bottom: 10px;
}

.table {
  font-size: 13px;
  border-collapse: separate; /* Allows for proper border-radius */
  border-spacing: 0; /* Ensures cells are properly spaced */
  width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  border: var(--thin-border);
}

.table-header {
  background-color: #f0f0f0;
}

.table-header th {
  border-bottom: 2px solid #ddd;
}

.table td,
.table th {
  padding: 8px;
  text-align: left;
  border-bottom: var(--thin-border);
}